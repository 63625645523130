import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Loading from '../components/loading'
import ShareModal from '../components/popup/share'
import View from '../components/presentation/history'
import { BoxGray } from '../components/template/box'

import authActions from '../state/auth/actions'
import homeActions from '../state/home/actions'

class HistoryPage extends React.Component {
  componentDidMount() {
    this.props.checkAuthorization(null, '/', null, {
      onSuccess: () => this.props.onLoad(),
    })
  }

  render() {
    if (!this.props.state.readyPageLogin) {
      return <Loading />
    }
    return (
      <Layout auth={this.props.auth.currentUser !== null}>
        <SEO keywords={[`invitree`]} />
        <BoxGray>
          <View
            onShare={this.props.onShare}
            onGoDetail={this.props.onGoDetail}
            data={this.props.state.listHistoryAll}
            locale={this.props.app.locale[this.props.app.lang]}
          />
        </BoxGray>
        <ShareModal
          id={this.props.state.selectedShareID}
          locale={this.props.app.locale[this.props.app.lang]}
        />
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.home,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      onShare: homeActions.onShare,
      onGoDetail: homeActions.onGoDetail,
      onLoad: homeActions.onLoadHistory,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryPage)
